<template lang="html">
  <section class="register container login column-center">
    <div class="contenedor-registro">
      <Form
        :validation-schema="schema"
        @submit="onSubmit"
        v-slot="{ errors }"
        class="row-center ais"
      >
        <div class="left">
          <router-link :to="{ name: 'login'}" class="volver">Volver</router-link>

          <h1 class="titulo-formulario-login">Registro</h1>

          <p class="intro-registro">
            Si es la primera vez que accedes rellena el siguiente formulario
            para que podamos validar tu acceso a la zona de profesionales
            Santiveri
          </p>

          <div id="nombre" class="column">
            <Field
              name="name"
              class="input-styled"
              placeholder="Nombre"
              :class="{ error: errors.name }"
              v-model="data.name"
            />
            <span class="mss-error">{{ errors.name }}</span>
          </div>

          <div id="apellidos" class="column">
            <Field
              name="surname"
              class="input-styled"
              placeholder="Apellido"
              :class="{ error: errors.surname }"
              v-model="data.surname"
            />
            <span class="mss-error">{{ errors.surname }}</span>
          </div>

          <div id="apellidos" class="column">
            <Field
              name="surname_2"
              class="input-styled"
              placeholder="Apellido"
              :class="{ error: errors.surname_2 }"
              v-model="data.surname_2"
            />

            <span class="mss-error">{{ errors.surname_2 }}</span>
          </div>

          <div class="column contenedor-pass">
            <Field
              name="password"
              class="input-styled"
              placeholder="Contraseña"
              :type="showPassword ? 'text' : 'password'"
              :class="{ error: errors.password_confirmation }"
              v-model="data.password"
            />
            <span class="mss-error">{{ errors.password }}</span>
            <a>
              <i @click="showPassword=!showPassword" :class="[this.showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa']"></i>
            </a>
          </div>

          <div class="column contenedor-pass">
            <Field
              name="password_confirmation"
              class="input-styled"
              placeholder="Repite contraseña"
              :type="showPassword ? 'text' : 'password'"
              :class="{ error: errors.password_confirmation }"
              v-model="data.password_confirmation"
            />
            <span class="mss-error">{{ errors.password_confirmation }}</span>
          </div>

          <div id="email" class="column">
            <Field
              name="email"
              class="input-styled"
              placeholder="Email"
              :class="{ error: errors.email }"
              v-model="data.email"
            />
            <span class="mss-error">{{ errors.email }}</span>
          </div>

          <div id="telefono" class="column">
            <Field
              name="phone"
              class="input-styled"
              placeholder="Teléfono"
              :class="{ error: errors.phone }"
              v-model="data.phone"
            />
            <span class="mss-error">{{ errors.phone }}</span>
          </div>
        </div>

        <div class="right">
          <div id="dir" class="column">
            <Field
              name="street"
              class="input-styled"
              placeholder="Dirección"
              :class="{ error: errors.street }"
              v-model="data.street"
            />
            <span class="mss-error">{{ errors.street }}</span>
          </div>
          <div id="codpos">
            <Field
              name="cp"
              class="input-styled"
              placeholder="Código Postal"
              :class="{ error: errors.cp }"
              v-model="data.cp"
            />
            <span class="mss-error">{{ errors.cp }}</span>
          </div>
          <div id="poblacion">
            <Field
              name="city"
              class="input-styled"
              placeholder="Población"
              :class="{ error: errors.city }"
              v-model="data.city"
            />
            <span class="mss-error">{{ errors.city }}</span>
          </div>
          <div id="country">
            <Field
              name="country"
              class="input-styled"
              placeholder="País"
              :class="{ error: errors.country }"
              v-model="data.country"
            />
            <span class="mss-error">{{ errors.country }}</span>
          </div>
          <div id="province" class="column">
            <Field
              name="province"
              as="select"
              class="select-styled"
              :class="{ error: errors.province }"
              v-model="data.province"
            >
               <option v-for="(option, key) in $tm('select_values.province')" :key="key" :value="key" :selected="[key == '1' ? 'selected' : '']">
                {{$rt(option) }}
              </option>
            </Field>
            <span class="mss-error">{{ errors.province }}</span>
          </div>

          <div id="profesion" class="column">
            <Field
              name="profesion"
              as="select"
              class="select-styled"
              :class="{ error: errors.profesion }"
              v-model="data.profesion"
            >
              <option v-for="(option, key) in $tm('select_values.profession')" :key="key" :value="key" :selected="[key == '1' ? 'selected' : '']">
                {{$rt(option) }}
              </option>

            </Field>
            <span class="mss-error">{{ errors.profesion }}</span>
          </div>
          <div id="especialidad" class="column">
            <Field
              name="speciality"
              as="select"
              class="select-styled"
              :class="{ error: errors.speciality }"
              v-model="data.speciality"
            >
             <option
              v-for="(option, key) in $tm('select_values.speciality')"
              :key="key"
              :value="key"
            >
              {{ $rt(option) }}
            </option>
            </Field>
            <span class="mss-error">{{ errors.speciality }}</span>
          </div>

          <div class="checkbox-styled">
            <Field
              id="newsletter"
              name="newsletter"
              type="checkbox"
              v-model="data.newsletter"
              :value="true"
              :unchecked-value="false"
            />

            <label for="newsletter">
              Acepto recibir las novedades de Santiveri por e-mail. Puedo darme
              de baja cuando quiera.
            </label>
          </div>
          <div class="checkbox-styled">
            <Field
              id="acceptance_legal"
              name="acceptance_legal"
              type="checkbox"
              v-model="data.acceptance_legal"
              :value="true"
              :unchecked-value="false"
            />
            <label
              for="acceptance_legal"
              :class="{ error: errors.acceptance_legal }"
            >
              He leído y acepto la
              <a href="http://www.santiveri.com/es/politica-de-privacidad"
                >Política de privacidad</a
              >
            </label>
            <span class="mss-error">{{ errors.acceptance_legal }}</span>
          </div>
          <div id="botones">
            <button class="boton-styled">Enviar</button>
          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script lang="js">
import { Form, Field} from "vee-validate";
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'register',
  props: [],
  data() {
    const schema = {
      name: 'required',
      surname: 'required',
      surname_2: 'required',
      password: 'required|min:8',
      password_confirmation: 'confirmed:@password',
      email: 'required|email',
      phone: 'required',
      province: 'required',
      country: 'required',
      city: 'required',
      cp: 'required',
      street: 'required',
      profesion: 'required',
      speciality: 'required',
      acceptance_legal: 'required'
    };
    return {
      schema,
      showPassword: false,
      data: {
        name: '',
        surname: '',
        surname_2: '',
        password: '',
        password_confirmation: '',
        email: '',
        phone: '',
        province: '',
        country: '',
        city: '',
        cp: '',
        street: '',
        profesion: '',
        speciality: '',
        newsletter: false,
        acceptance_legal: false
      }
    }
  },
  methods: {
    ...mapMutations({
      launchAlert: "alert/launchAlert",
    }),
    ...mapActions({
      createRegister: 'auth/createRegister',
    }),
    onSubmit() {
      this.createRegister(this.data).then(r => {
        this.launchAlert('El registro se realizó con éxito. Le avisaremos en cuanto su cuenta sea verificada.');
        this.$router.push({ name: 'login' });
      })
    }
  },
  components: {
    Field,
    Form
  }
}
</script>

<style scoped lang="scss">
.mss-error {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 285px;
  color: #d32626;
}
.contenedor-registro .input-styled,
.contenedor-registro .select-styled {
  margin-bottom: 0;
}
.checkbox-styled {
  margin-bottom: 5px;
}
</style>
